// import * as login from './rest/login';

//import { companies } from './rest/companies';

import * as companies from './rest/companies'
import * as agreement from './rest/agreements'
import * as google from './rest/Google'
import * as files from './rest/files'
import * as games from './rest/games'
import * as tournaments from './rest/tournaments'
import * as countries from './rest/Countries'
import * as championship from './rest/championship'
import * as users from './rest/users'
import * as participants from './rest/participants'
import * as systemParameters from './rest/systemParameters'
import * as attributionPlatforms from './rest/attributionPlatforms'
import * as gameSource from './rest/gameSource'
import * as gameTypes from './rest/gameTypes'
import * as platforms from './rest/platforms'
import * as scoreOptions from './rest/scoreOptions'
import * as roles from './rest/roles'
import * as contactInfoTypes from './rest/contactInfoTypes'
import * as prizeBreakdownTypes from './rest/prizeBreakdownTypes'
import * as prizeBreakdown from './rest/prizeBreakdown'
import * as timeFrames from './rest/timeFrames'
import * as tags from './rest/tags'
import * as streaming from './rest/streaming'
import * as participationsProfile from './rest/participationsProfile'
import * as wallets from './rest/wallets'
import * as participations from './rest/participations';
import * as deviceMetaDatas from './rest/deviceMetaDatas';
import * as auth from './rest/auth';
import * as setup from './rest/setup';
import * as transactions from './rest/transactionAdditions';
import * as rewards from './rest/rewards';
import * as quests from './rest/quests'
export * from 'axios'

const api = {
  companies,
  agreement,
  google,
  files,
  games,
  tournaments,
  countries,
  championship,
  users,
  participants,
  systemParameters,
  attributionPlatforms,
  gameSource,
  gameTypes,
  platforms,
  scoreOptions,
  roles,
  contactInfoTypes,
  prizeBreakdownTypes,
  prizeBreakdown,
  timeFrames,
  tags, 
  streaming,
  participationsProfile,
  wallets,
  participations,
  deviceMetaDatas,
  auth, 
  setup, 
  transactions,
  rewards,
  quests
}

export default api
