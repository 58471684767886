import coreClient from '../coreClient'
import identityClient from '../identityClient'
import {
  DeviceMetaDataPayload,
  ParticipationDeviceMetaDataPayload,
} from '../models/response/DeviceMetaDataPayload'

export function getParticipationDevice(userId: number | string, participationId?: number | string) {
  let path = `/DeviceMetadatas`
  if (userId !== null || participationId !== null) {
    path += `?`
  }
  if (userId) {
    path += `userId=${userId}&`
  }
  if (participationId) {
    path += `ParicipationId=${participationId}`
  }
  return coreClient.get<DeviceMetaDataPayload[]>(path)
}

export function get(userId: number | string, groupBy?: number | string) {
  let path = `/DeviceMetadatas/Group`
  if (userId !== null || groupBy !== null) {
    path += `?`
  }
  if (userId) {
    path += `userId=${userId}&`
  }
  if (groupBy) {
    path += `GroupBy=${groupBy}`
  }
  return identityClient.get<DeviceMetaDataPayload[]>(path)
}

export function removeDeviceMetaData(userId: string) {
  return identityClient.delete(`/DeviceMetadatas/Batch?UserId=${userId}`)
}
